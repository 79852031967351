.user-picture {
    .card {
        overflow: hidden;
    }
    h3 {
        text-align: center;
        margin-top: 1.3rem;
    }
    .img-object-large-fit {
        display: block;
        margin: 0 auto;
    }
}

.user-view {
    .title p {
        font-weight: 600;
    }
}