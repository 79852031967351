.btn-big-icon{
    background:none !important;
    border:#8cbbbf 1px solid !important;
    color:#175c5f;
    height:12rem;
    i{}
    img{height:4.5rem;width:6rem}
}

.img-object-large-fit {
    display: inline-block;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
}

.modal{
    strong{font-weight:600}
    .disabled{pointer-events: none;}
    .btn-big-icon{border:1px solid #DDD !important;
        width:9rem;
        color:#000 !important;
        div{font-size:0.8rem;}
    &:hover
    {background:#fff !important;box-shadow:0rem 1rem 2rem rgba(0,0,0,0.1);color:#000 !important}
}

}

.modal-footer{
    display:none !important;
}


.btn-outline-secondary{
    border:1px solid #175c5f66 !important;
}

.modal.medical{background:inherit;}

.btn-padded{text-align: center;}

.mx-auto.col-lg-10{
    a{margin:0.25rem}
}

.col-form-label.col-sm-8 {
    font-weight:600;
    text-align: right;
}

.form-group{margin-bottom:0rem;}

.override
{
    a{
        background:#666 !important;
    }
}

.user-view
{
    .alert{
        text-align:right;
        display:inline-block !important;
        width:40%;
        float:right;
    }
}


.basic-layout.search-included .header{
    background-color:#bdd8da !important;
}
.medical .basic-layout{background-color:#fff !important}

.checked-ciricle{
    display:block;
    width:1.25rem;
    height:1.25rem;
    background:#ccc;
    border-radius:1rem;
    margin-left:0.75rem;
    div{
        display:inline-block;
        width:0.9rem;
        height:0.9rem;
        background:green;
        border-radius:1rem;
        margin-bottom: calc(0.35rem / 2);
        margin-top: calc(0.35rem / 2);
    }
}

.empty-ciricle{
    display:block;
    width:1.25rem;
    height:1.25rem;
    background:#ccc;
    border-radius:1rem;
    margin-left:0.75rem;
    div{
        display:inline-block;
        width:0.9rem;
        height:0.9rem;
        border-radius:1rem;
        margin-bottom: calc(0.35rem / 2);
        margin-top: calc(0.35rem / 2);
    }
}

button.btn:focus{
    box-shadow:none;
}

.form-group {
    .col-sm-8.col-form-label {
        text-align: left;
    }
}